<template>
  <div v-bind="$attrs">
    <div :class="{ 'flex flex-1 flex-col': !props.withBackground }">
      <div
        v-if="date || (props.readTime && props.addMoreInfo) || tag"
        :style="{ minHeight: '24px' }"
        class="relative mt-2.5 hidden items-center lg:static lg:min-h-0 xl:mb-3 xl:mt-0 xl:flex"
        :class="{
          'order-1 flex-wrap justify-end gap-y-2 text-blue-100 lg:justify-start xl:order-none':
            !props.withBackground,
          'justify-between': props.withBackground,
        }"
      >
        <div class="flex w-full">
          <span
            v-if="date"
            class="relative text-sm lg:!static"
            :class="{
              '!absolute right-[calc(100%+2.5rem)]': !props.withBackground,
            }"
            :title="props.datetime"
          >
            <time :datetime="props.datetime">{{ date }}</time>
          </span>
          <span
            v-if="props.readTime && props.addMoreInfo"
            class="hidden items-center text-sm before:mx-2 before:block before:h-3 before:w-px before:bg-blue-100 before:content-[''] lg:mb-2.5 lg:flex xl:mb-0"
          >
            <UiIcon
              name="clock-thin"
              :width="12"
              :height="12"
              class="mr-1"
            />
            {{ props.readTime }}
            {{ props.labels?.readTime }}
          </span>
        </div>
        <UiTag
          v-if="tag"
          :tag="tag"
          :show-hash="true"
          :variant="props.withBackground ? 'white' : 'outlet'"
        />
      </div>
      <p
        class="line-clamp-3 text-base font-bold"
        :class="{
          'mt-0 text-primary': !props.withBackground,
          'mt-3.5 text-white xl:mt-0': props.withBackground,
        }"
      >
        {{ props.title }}
      </p>
      <div class="hidden text-base font-normal xl:block">
        <div
          class="pt-1.5 text-sm text-black [&_p]:text-sm [&_p]:text-inherit"
          :class="[
            props.withBackground ? 'xl:line-clamp-5' : 'xl:line-clamp-3',
          ]"
        >
          <slot
            v-if="props.teaser"
            v-bind="{ teaser: props.teaser }"
          >
            {{ props.teaser }}
          </slot>
        </div>
      </div>
    </div>
    <slot
      name="actions"
      v-bind="{
        withBackground: props.withBackground,
        buttonText: props.moreLink,
        slug: props.slug,
      }"
    >
      <div class="mt-4 hidden text-sm xl:block">
        <UiButton
          :variant="props.withBackground ? 'transparent' : 'circle'"
          :with-animation="!Boolean(props.withBackground)"
          class="group-hover:!px-9 hover:bg-primary hover:text-white"
          :class="
            props.withBackground
              ? 'group-hover:border-white group-hover:text-white'
              : ''
          "
        >
          {{ props.moreLink }}
        </UiButton>
      </div>
    </slot>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import UiButton from '../../../../UiButton/UiButton.vue'
import UiTag from '../../../../UiTag/UiTag.vue'
import UiIcon from '../../../../UiIcon/UiIcon.vue'
import type { UiNewsItemCommon } from '../../UiNewsListItem.types'

interface UiNewsListItemText extends UiNewsItemCommon {
  withBackground?: boolean
}

const props = withDefaults(defineProps<UiNewsListItemText>(), {
  teaser: '',
  datetime: '',
  datetimeShort: '',
  readTime: undefined,
  moreLink: '',
  withBackground: false,
  addMoreInfo: true,
  slug: '',
  labels: null,
})

const date = computed(() => props.datetime?.replace(/-/g, '.'))

const tag = computed(() => {
  return props.tags?.[0]?.title
})
</script>
